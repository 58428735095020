
import comarcasResidencia from "@/core/data/comarcas";
import { nivel, nombreIdioma } from "@/core/data/idiomas";
import { dias, horarios } from "@/core/data/tiemposProfesionales";
import { sinTitulaciones, titulaciones } from "@/core/data/titulaciones";
import tratamientos from "@/core/data/tratamientos";
import vehiculos from "@/core/data/vehiculos";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { FormularioEstado } from "@/enums";
import Page from "@/layout/Page.vue";
import { DatosSolicitud } from "@/store/models";
import {
  AuthModule,
  OrganismosModule,
  ProfesionalesFormulariosModule,
} from "@/store/modules";
import { IAMFile } from "@/types";
import { ArrowDown } from "@element-plus/icons-vue";
import imageCompression from "browser-image-compression";
import Dropzone from "dropzone";
import { ElNotification } from "element-plus";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { ErrorMessage, useField, useForm } from "vee-validate";
import { computed, defineComponent, onBeforeMount, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { getModule } from "vuex-module-decorators";
import * as Yup from "yup";

interface Formulario {
  notas: string;
  otrosDocumentos?: IAMFile[];
}

export default defineComponent({
  name: "formularios-familia-detalle",
  components: {
    ErrorMessage,
    Page,
    ArrowDown,
  },

  methods: {
    includeDocumentoNuevo(file) {
      const reader = new FileReader();
      return new Promise<void>((resolve, reject) => {
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.otrosDocumentos.push({
            nombre: file.name,
            data: reader.result?.toString() || "",
          });
          if (Object.keys(this.erroresDocumentos).length === 0) {
            this.isReading = false;
          } else {
            this.isReading = true;
          }
          resolve();
        };
        reader.onerror = () => {
          this.erroresDocumentos.value[file.name] = true;
          this.isReading = true;
          reject();
        };
      });
    },
    deleteDocumentoNuevo(file) {
      this.otrosDocumentos = this.otrosDocumentos.filter(function (item) {
        return item.nombre !== file.name;
      });

      if (Object.keys(this.erroresDocumentos).length === 0) {
        this.isReading = false;
      }
    },
    compressImage(file) {
      const compressionOptions = { maxSizeMB: 0.3 };
      return imageCompression(file, compressionOptions);
    },
  },
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const router = useRouter();
    const token = route.params.id;

    const store = useStore();
    const module = getModule(ProfesionalesFormulariosModule, store);
    const moduleOrganismos = getModule(OrganismosModule, store);
    const comarcas = computed(() => moduleOrganismos.organismosListado);
    const authModule = getModule(AuthModule, store);
    const comarcaId = authModule.user.comarcaId;
    const error = computed(() => module.errors);
    const formLoading = ref(false);

    watch(error, () => {
      if (!error.value) return;

      Swal.fire({
        text:
          t("errors." + (error.value.error.code ?? error.value.error.name)) ??
          error.value.error.name,
        icon: "error",
        buttonsStyling: false,
        confirmButtonText: t("dialogs.okButton"),
        customClass: {
          confirmButton: "btn btn-primary",
        },
      });
    });

    const cargaDetalle = ref<boolean>(false);
    const estado = ref<string>("");
    const claseEstado = ref<string>("");

    onBeforeMount(async () => {
      cargaDetalle.value = true;
      try {
        await module.fetchById({ comarcaId, id: token as string });
        await moduleOrganismos.fetchAll();
      } catch (err) {
        Swal.fire({
          text: t("formularios.profesionalDetalle.cargar.error"),
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: t("dialogs.okButton"),
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
      } finally {
        cargaDetalle.value = false;
      }
    });

    const isLoadingAprobar = ref<boolean>(false);
    const isLoadingNoApto = ref<boolean>(false);
    const isLoadingPendiente = ref<boolean>(false);
    const isLoadingRealizadoPorError = ref<boolean>(false);
    const profesionalComarcaId = ref<string>();

    const profesional = computed(() => module.profesionalFormulario);

    const datosSolicitud = computed(
      () => profesional?.value?.datosSolicitud ?? ({} as DatosSolicitud)
    );

    const titulacionAdjunta = computed(
      () => profesional?.value?.datosAdjuntos?.titulacionAdjunta ?? []
    );

    const certificadoAntecedentes = computed(
      () => profesional?.value?.datosAdjuntos?.certificadoAntecedentes ?? []
    );

    const otrosDocumentosVisor = computed(
      () => profesional?.value?.datosAdjuntos?.otrosDocumentos ?? []
    );

    const tratamiento = computed(() => {
      const result = tratamientos.find(
        (tr) => tr.id === profesional.value?.datosSolicitud?.tratamiento
      );

      return result ?? tratamientos[3];
    });

    const schema = Yup.object({
      notas: Yup.string().ensure(),
      otrosDocumentos: Yup.array(),
    });

    const { resetForm, handleSubmit } = useForm<Formulario>({
      validationSchema: schema,
    });

    const { value: notas } = useField("notas");
    const { value: otrosDocumentos } = useField<IAMFile[]>("otrosDocumentos");

    watch(profesional, () => {
      if (!profesional.value) return;

      estado.value = profesional.value?.estado.replaceAll("_", " ");
      switch (estado.value.replaceAll(" ", "_")) {
        case "PENDIENTE":
          claseEstado.value = "badge badge-primary";
          break;
        case "INCORPORADO":
          claseEstado.value = "badge badge-success";
          break;
        case "NO_APTO":
        case "REALIZADO_POR_ERROR":
          claseEstado.value = "badge badge-danger";
          break;
        default:
          claseEstado.value = "d-none";
          break;
      }
      profesionalComarcaId.value = profesional.value?.comarcaId;

      resetForm({
        values: {
          notas: profesional.value.notas ?? "",
          otrosDocumentos:
            profesional.value.datosAdjuntos.otrosDocumentos || [],
        },
      });
      setCurrentPageBreadcrumbs(profesional.value?.codigo ?? "", [
        t("menu.solicitudes"),
        { title: t("menu.profesionales"), path: "/solicitudes/profesionales" },
      ]);
    });

    const isReading = ref<boolean>(false);
    let erroresDocumentos = ref<{ [key: string]: boolean }>({});

    return {
      isAdmin: !comarcaId,
      tratamiento,
      profesional,
      datosSolicitud,
      profesionalComarcaId,
      nombreIdioma,
      nivel,
      comarcas,
      comarcasResidencia,
      titulaciones,
      sinTitulaciones,
      titulacionAdjunta,
      certificadoAntecedentes,
      otrosDocumentosVisor,
      otrosDocumentos,
      dias,
      horarios,
      vehiculos,
      notas,
      formLoading,
      FormularioEstado,
      cargaDetalle,
      estado,
      claseEstado,
      isReading,
      erroresDocumentos,
      changeComarca: async (newValue) => {
        try {
          await module.cambiarComarca({
            id: token as string,
            comarcaId: newValue,
          });
        } catch (err) {
          Swal.fire({
            text: t("formularios.profesionalDetalle.cambiarComarca.error"),
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: t("dialogs.okButton"),
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        }
      },
      aprobar: async () => {
        isLoadingAprobar.value = true;
        try {
          await module
            .aprobar({
              comarcaId: profesional.value?.comarcaId ?? "",
              id: token as string,
            })
            .then(() => {
              if (!module.errors) {
                //SNA: Necesario porque si no, el "const error = computed(() => module.errors);" no salta. Se calcula al salir de la función.
                Swal.fire({
                  text: t(
                    "formularios.profesionalDetalle.aprobar.mensaje.exito"
                  ),
                  icon: "success",
                  buttonsStyling: false,
                  confirmButtonText: t("dialogs.okButton"),
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                }).then(() => {
                  router.replace("/solicitudes/profesionales");
                });
              }
            });
        } catch (err) {
          Swal.fire({
            text: t("formularios.profesionalDetalle.aprobar.error"),
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: t("dialogs.okButton"),
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        } finally {
          isLoadingAprobar.value = false;
        }
      },
      noApto: async () => {
        isLoadingNoApto.value = true;
        try {
          await module
            .noApto({
              comarcaId: profesional.value?.comarcaId ?? "",
              id: token as string,
            })
            .then(() => {
              if (!module.errors) {
                //SNA: Necesario porque si no, el "const error = computed(() => module.errors);" no salta. Se calcula al salir de la función.
                Swal.fire({
                  text: t(
                    "formularios.profesionalDetalle.descartar.mensaje.exito"
                  ),
                  icon: "success",
                  buttonsStyling: false,
                  confirmButtonText: t("dialogs.okButton"),
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                }).then(() => {
                  router.replace("/solicitudes/profesionales");
                });
              }
            });
        } catch (err) {
          Swal.fire({
            text: t("formularios.profesionalDetalle.descartar.error"),
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: t("dialogs.okButton"),
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        } finally {
          isLoadingNoApto.value = false;
        }
      },
      realizadoPorError: async () => {
        isLoadingRealizadoPorError.value = true;
        try {
          await module
            .realizadoPorError({
              comarcaId: profesional.value?.comarcaId ?? "",
              id: token as string,
            })
            .then(() => {
              if (!module.errors) {
                //SNA: Necesario porque si no, el "const error = computed(() => module.errors);" no salta. Se calcula al salir de la función.
                Swal.fire({
                  text: t(
                    "formularios.profesionalDetalle.realizadoPorError.mensaje.exito"
                  ),
                  icon: "success",
                  buttonsStyling: false,
                  confirmButtonText: t("dialogs.okButton"),
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                }).then(() => {
                  router.replace("/solicitudes/profesionales");
                });
              }
            });
        } catch (err) {
          Swal.fire({
            text: t("formularios.profesionalDetalle.descartar.error"),
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: t("dialogs.okButton"),
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        } finally {
          isLoadingRealizadoPorError.value = false;
        }
      },
      pendiente: async () => {
        isLoadingPendiente.value = true;
        try {
          await module
            .pendiente({
              comarcaId: profesional.value?.comarcaId ?? "",
              id: token as string,
            })
            .then(() => {
              if (!module.errors) {
                //SNA: Necesario porque si no, el "const error = computed(() => module.errors);" no salta. Se calcula al salir de la función.
                Swal.fire({
                  text: t(
                    "formularios.profesionalDetalle.pendiente.mensaje.exito"
                  ),
                  icon: "success",
                  buttonsStyling: false,
                  confirmButtonText: t("dialogs.okButton"),
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                }).then(() => {
                  router.replace("/solicitudes/profesionales");
                });
              }
            });
        } catch (err) {
          Swal.fire({
            text: t("formularios.profesionalDetalle.pendiente.error"),
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: t("dialogs.okButton"),
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        } finally {
          isLoadingPendiente.value = false;
        }
      },
      isLoadingAprobar,
      isLoadingNoApto,
      isLoadingPendiente,
      isLoadingRealizadoPorError,
      onSubmit: handleSubmit(async (values) => {
        formLoading.value = true;
        try {
          await module.update({
            comarcaId: profesional.value?.comarcaId ?? "",
            id: token as string,
            data: values,
          });
          Swal.fire({
            text: t("formularios.familiaDetalle.guardar.exito"),
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: t("dialogs.okButton"),
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        } catch (err) {
          Swal.fire({
            text: t("formularios.familiaDetalle.guardar.error"),
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: t("dialogs.okButton"),
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        } finally {
          formLoading.value = false;
        }
      }),
    };
  },
  mounted() {
    const { t } = useI18n();
    const dropzoneNuevosDocumentos = new Dropzone(
      "#kt_dropzonejs_otros_documentos",
      {
        url: process.env.VUE_APP_API_URL,
        // The name that will be used to transfer the file
        paramName: "documento",
        maxFilesize: 10, // MB
        addRemoveLinks: true,
        uploadMultiple: true,
        autoProcessQueue: false,
        acceptedFiles: "image/*,application/pdf",
      }
    );
    dropzoneNuevosDocumentos.on("addedfile", async (file) => {
      this.isReading = true;
      try {
        const fileToAdd = file.type.includes("image/")
          ? await this.compressImage(file)
          : file;

        this.includeDocumentoNuevo(fileToAdd);
        ElNotification({
          message: t("formularios.profesionalDetalle.guarda.terminado"),
          type: "info",
        });
      } catch (err) {
        Swal.fire({
          text: t("formularios.profesionalDetalle.guarda.errorArchivo"),
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: t("dialogs.okButton"),
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
        this.erroresDocumentos[file.name] = true;
        this.isReading = true;
      } finally {
        if (Object.keys(this.erroresDocumentos).length === 0) {
          this.isReading = false;
        } else {
          this.isReading = true;
        }
      }
    });
    dropzoneNuevosDocumentos.on("removedfile", (file) => {
      this.deleteDocumentoNuevo(file);
      delete this.erroresDocumentos[file.name];

      if (Object.keys(this.erroresDocumentos).length === 0) {
        this.isReading = false;
      }
    });
  },
});
